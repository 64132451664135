import React from 'react';
import './App.css';
import imagen from './images/imagen.jpg';

function App() {
  return (
    <div className="App">
      <div className="background-image" style={{ backgroundImage: `url(${imagen})` }}></div>
    </div>
  );
}

export default App;
